import countryListAllIsoData from "@/comman/AllStatesCodeArray";
import localStorageCall from "@/services/Methods/localstorage.hook";
import _ from "lodash";
import { useEffect } from "react";

const useIpDetection = (STORES, currentStore, router, pathname,isUserLogin) => {

    useEffect(() => {
        const DATASTORE = sessionStorage.getItem('alreadyRedirect');
        if (!DATASTORE && (_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === currentStore))) {
            success();
        } else if (DATASTORE && (_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === currentStore)) && !_.includes(STORES, currentStore)) {
            success();
        }
    }, []);

    async function success(callback = null) {
        const response = await fetch('https://api.ipify.org/');
        const ip = await response.text();
        if (ip) {
            const CurrentCountry = await fetch(`https://pro.ip-api.com/json/${ip}?key=QzWizLOfUTKbkWQ`);
            const VALUES = await CurrentCountry.json();
             localStorageCall().setItem('IpCountry',VALUES?.countryCode);
            if (!callback) {
                sessionStorage.setItem('alreadyRedirect', 'yes');
                if (_.includes(STORES, _.lowerCase(VALUES?.countryCode))) {
                    if (_.split(pathname, '/')?.length > 0 && _.split(pathname, '/')[2]) {
                        if(!isUserLogin){
                        router.push(`/${_.lowerCase(VALUES?.countryCode)}/${_.split(pathname, '/')[2]}`);
                        }
                    } else {
                        if(!isUserLogin){
                          router.push(`/${_.lowerCase(VALUES?.countryCode)}`);
                        }
                    }
                } else {
                    if (_.split(pathname, '/')?.length > 0 && _.split(pathname, '/')[2]) {
                        if(!isUserLogin){
                           router.push(`/us/${_.split(pathname, '/')[2]}`);
                        }
                    } else {
                        if(!isUserLogin){
                        router.push(`/us`);
                        }
                    }
                }
            } else {
                sessionStorage.setItem('alreadyRedirect', 'yes');
                if (_.includes(STORES, _.lowerCase(VALUES?.countryCode))) {
                    callback(VALUES?.countryCode?.toLowerCase());
                } else {
                    callback(`us`);
                }
            }
        }
    };


    return {
        success
    }

}

export default useIpDetection;