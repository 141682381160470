import { useEffect } from 'react';
import * as Sentry from "@sentry/nextjs";

const useScript = (data, callback) => {

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.Sentry = Sentry;
        }
        const script = document.createElement('script');
        const head = document.querySelector('head');
        if (data) {
            script.async = true;
            script.append(`
               function onSucessFunciton(res) {
                    try {
                        console.log("SuccessResponse", res);
                        if (window.Sentry) {
                            window.Sentry.captureMessage("Payment success event received", { extra: res });
                        }
                        if (res?.orderId || res?.tempOrderId) {
                            localStorage.setItem('orderId', String(res?.orderId || res?.tempOrderId));
                        } else if (res?.token) {
                            localStorage.setItem('order_token', res?.token);
                        } else if (res?.preAuthOrderId) {
                            localStorage.setItem('preAuthOrderId', String(res?.preAuthOrderId));
                        }
                    } catch (error) {
                        console.error("Error in onSuccessFunction:", error);
                        if (window.Sentry) {
                            window.Sentry.captureMessage("Error in Payment Success Handler", { extra: error });
                        }
                    }
                }

                function onErrorFunction(res) {
                    try {
                        console.log("ErrorResponse", res);
                        if (window.Sentry) {
                            window.Sentry.captureMessage("Payment Error event received", { extra: res });
                        }
                        localStorage.setItem('error-payment', res?.response);
                    } catch (error) {
                        console.error("Error in onErrorFunction:", error);
                        if (window.Sentry) {
                            window.Sentry.captureMessage("Error in Payment Error Handler", { extra: error });
                        }
                    }
                }                   
            `);
            script.append(data);
            script.type = "text/javascript";
            head.appendChild(script);
            return () => {
                head.removeChild(script);
            }
        }
    }, [data]);
};

export default useScript;